import * as types from '../actions/dashboardActions';

const initState = {
  ini_data: null,
  data: null,
  initiation_count: {
    ini_count: '0',
    pr_count: '0',
    qr_count: '0',
    qa_count: '0',
  },
  ini_totalCount: 0,
  department_chart: {},
  project_chart: {},
  purpose_chart: {},
  imp_data: null,
  implementation_count: {
    capa_implementation: '0',
    capa_closure: '0',
    check_performed: '0',
    capa_retired: '0',
  },
  imp_totalCount: 0,
};

const dashboard = (state = initState, action) => {
  switch (action.type) {
    case types.GET_DASHBOARD_DATA:
      return {
        ...state,
        ini_data: action.data.data,
        ini_totalCount: action.data.data.length,
        initiation_count: action.initiation_count,
        department_chart: action.data.depatment_chart[0] || {},
        project_chart: action.data.project_chart[0] || {},
        purpose_chart: action.data.purpose_chart[0] || {},
      };

    case types.GET_DASHBOARD_IMP:
      return {
        ...state,
        imp_data: action.data,
        implementation_count: action.data,
        imp_totalCount: action.total,
      };
    case types.GET_DASHBORD_INITIATION:
      return {
        ...state,
        data: action.data,
        excelData: action.excelData,
        totalCount: action.data.length,
      };
    case types.GET_DASHBOARD_ACTIONITEM:
      return {
        ...state,
        data: action.data,
        excelData: action.excelData,
        totalCount: action.data.length,
      };
    default:
      return state;
  }
};

export default dashboard;

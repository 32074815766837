import {
  https,
  cmmHttps,
  mtfHttps,
  fetch,
  dncHttps,
  rmHttps,
  ncoHttps,
  testHttps
} from '../../utils/config';
import {
  formatHistoryTableData,
  formatLogExcelData,
  formatNCOLogExcelData,
} from '../../utils/helper';
import { message } from 'antd';
import { MODULE } from '../../utils/constants';

export const FORM_DATA = 'FORM_DATA';
export const FORM_DETAILS = 'FORM_DETAILS';
export const LOG_DATA = 'LOG_DATA';
export const HISTORY_DETAILS = 'HISTORY_DETAILS';
export const AUDIT_REPORT = 'AUDIT_REPORT';
export const GET_REPORT_PERMS = 'GET_REPORT_PERMS';
export const RESET_REPORT_PERMS = 'RESET_REPORT_PERMS';
export const RCA_LOG_DATA = 'RCA_LOG_DATA';
export const EFFECTIVE_LOG_DATA = 'EFFECTIVE_LOG_DATA';
export const CAPA_LOG_DATA = 'CAPA_LOG_DATA';
export const CAPA_HISTORY_DATA = 'CAPA_HISTORY_DATA';

export const getFormReports =
  (no = '') =>
  (dispatch) => {
    const organization = localStorage.getItem('organization');
    const module = localStorage.getItem('module');

    if (module === MODULE.rm) {
      dispatch(changeControlForms(null));
      rmHttps
        .get(
          `/getchangeControlformview.php?organization=${organization}&risk_id=${no}`
        )
        .then(({ data }) => {
          const res = Array.isArray(data) ? data : [];
          dispatch(changeControlForms(res));
        })
        .catch((err) => {
          dispatch(changeControlForms(err));
          console.log(err);
        });
      return;
    }
    if (module === MODULE.nco) {
      //dispatch(changeControlForms(null));
      ncoHttps
        .get(
          `/getchangeControlformview.php?organization=${organization}&nco_no=${no}`
        )
        .then(({ data }) => {
          const res = Array.isArray(data) ? data : [];
          dispatch(changeControlForms(res));
        })
        .catch((err) => {
          dispatch(changeControlForms(err));
          console.log(err);
        });
      return;
    }
    if (module === MODULE.dnc) {
      dispatch(changeControlForms(null));
      dncHttps
        .get(
          `/getchangeControlformview.php?organization=${organization}&d_no=${no}`
        )
        .then(({ data }) => {
          const res = Array.isArray(data) ? data : [];
          dispatch(changeControlForms(res));
        })
        .catch((err) => {
          dispatch(changeControlForms(err));
          console.log(err);
        });
      return;
    }

    if (module === MODULE.mtf) {
      dispatch(memoToFileForms(null));
      mtfHttps
        .get(
          `/getchangeControlformview.php?organization=${organization}&mf_no=${no}`
        )
        .then(({ data }) => {
          const res = Array.isArray(data) ? data : [];
          dispatch(memoToFileForms(res));
        })
        .catch((err) => {
          dispatch(memoToFileForms([]));
          console.log(err);
        });
      return;
    }
    dispatch(changeControlForms(null));

    if (module === MODULE.cmm) {
      cmmHttps
        .get(
          `/getchangeControlformview.php?organization=${organization}&cc_no=${no}`
        )
        .then(({ data }) => {
          const res = Array.isArray(data) ? data : [];
          dispatch(changeControlForms(res));
        })
        .catch((err) => {
          dispatch(changeControlForms([]));
          console.log(err);
        });
      return;
    }

    https
      .get(
        `/getchangeControlformview.php?organization=${organization}&cc_no=${no}`
      )
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        dispatch(changeControlForms(res));
      })
      .catch((err) => {
        dispatch(changeControlForms([]));
        console.log(err);
      });
  };

export const getFormDetails = (formData) => (dispatch) => {
  const obj = JSON.stringify(formData);
  const module = localStorage.getItem('module');
  if (module === MODULE.rm) {
    rmHttps
      .post('/getchangeControlform.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: FORM_DETAILS, data: res });
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.dnc) {
    dncHttps
      .post('/getchangeControlform.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: FORM_DETAILS, data: res });
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.mtf) {
    mtfHttps
      .post('/getInitiation.php', { ...formData, type: 'main' })
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: FORM_DETAILS, data: res });
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.cmm) {
    cmmHttps
      .post('/getchangeControlform.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: FORM_DETAILS, data: res });
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.nco) {
    ncoHttps
      .post('/getchangeControlform.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: FORM_DETAILS, data: res });
      })
      .catch((err) => console.log(err));
    return;
  }
  https
    .post('/getchangeControlform.php', obj)
    .then(({ data }) => {
      const res = data[0];
      dispatch({ type: FORM_DETAILS, data: res });
    })
    .catch((err) => console.log(err));
};

export const getAudit = (formData) => (dispatch) => {
  const module = localStorage.getItem('module');
  if (module === MODULE.rm) {
    rmHttps
      .post('/getAudit.php', formData)
      .then(({ data }) => {
        dispatch({ type: AUDIT_REPORT, data });
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.nco) {
    ncoHttps
      .post('/getAudit.php', formData)
      .then(({ data }) => {
        dispatch({ type: AUDIT_REPORT, data });
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.dnc) {
    dncHttps
      .post('/getAudit.php', formData)
      .then(({ data }) => {
        dispatch({ type: AUDIT_REPORT, data });
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module !== MODULE.mtf) {
    if (module === MODULE.cmm) {
      cmmHttps
        .post('/getAudit.php', formData)
        .then(({ data }) => {
          dispatch({ type: AUDIT_REPORT, data });
        })
        .catch((err) => console.log(err));
      return;
    }
    https
      .post('/getAudit.php', formData)
      .then(({ data }) => {
        dispatch({ type: AUDIT_REPORT, data });
      })
      .catch((err) => console.log(err));
    return;
  }
  mtfHttps
    .post('/getAudit.php', formData)
    .then(({ data }) => {
      dispatch({ type: AUDIT_REPORT, data });
    })
    .catch((err) => console.log(err));
  return;
};

export const getLogReports =
  (no = '') =>
  (dispatch) => {
    const organization = localStorage.getItem('organization');
    const module = localStorage.getItem('module');

    if (module === MODULE.rm) {
      dispatch(changeControlLogs(null));
      rmHttps
        .get(`/getReports.php?organization=${organization}&risk_id=${no}`)
        .then(({ data }) => {
          const res = Array.isArray(data) ? data : [];
          dispatch(changeControlLogs(res));
        })
        .catch((err) => {
          dispatch(changeControlLogs([]));
          console.log(err);
        });
      return;
    }
    if (module === MODULE.nco) {
      //dispatch(changeControlLogs(null));
      ncoHttps
        .get(`/getReports.php?organization=${organization}&nco_no=${no}`)
        .then(({ data }) => {
          const res = Array.isArray(data) ? data : [];
          dispatch(changeControlLogs(res));
        })
        .catch((err) => {
          dispatch(changeControlLogs([]));
          console.log(err);
        });
      return;
    }
    if (module === MODULE.dnc) {
      dispatch(changeControlLogs(null));
      dncHttps
        .get(`/getReports.php?organization=${organization}&d_no=${no}`)
        .then(({ data }) => {
          const res = Array.isArray(data) ? data : [];
          dispatch(changeControlLogs(res));
        })
        .catch((err) => {
          dispatch(changeControlLogs([]));
          console.log(err);
        });
      return;
    }
    if (module === MODULE.mtf) {
      dispatch(memoToFileLogs(null));
      mtfHttps
        .get(`/getReports.php?organization=${organization}&mf_no=${no}`)
        .then(({ data }) => {
          const res = Array.isArray(data) ? data : [];
          dispatch(memoToFileLogs(res));
        })
        .catch((err) => {
          dispatch(memoToFileLogs([]));
          console.log(err);
        });
      return;
    }
    dispatch(changeControlLogs(null));
    if (module === MODULE.cmm) {
      cmmHttps
        .get(`/getReports.php?organization=${organization}&cc_no=${no}`)
        .then(({ data }) => {
          const res = Array.isArray(data) ? data : [];
          dispatch(changeControlLogs(res));
        })
        .catch((err) => {
          dispatch(changeControlLogs([]));
          console.log(err);
        });
      return;
    }
    https
      .get(`/getReports.php?organization=${organization}&cc_no=${no}`)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        dispatch(changeControlLogs(res));
      })
      .catch((err) => {
        dispatch(changeControlLogs([]));
        console.log(err);
      });
  };

export const getHistoryReports = (no) => (dispatch) => {
  const module = localStorage.getItem('module');

  if (!no) {
    dispatch(clearHistoryReports());
    return;
  }

  if (module === MODULE.rm) {
    const JSONObject = JSON.stringify({ risk_id: no });
    rmHttps
      .post('/getchangeControlhistory.php', JSONObject)
      .then(({ data }) => {
        if (data.length === 0) {
          dispatch(clearHistoryReports());
          return;
        }

        if (Array.isArray(data)) {
          const details = data[0];
          const dataSource = formatHistoryTableData(details, 'rm');
          dispatch({
            type: HISTORY_DETAILS,
            data: details,
            historyTable: dataSource,
          });
        } else {
          dispatch(clearHistoryReports());
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  }
  if (module === MODULE.nco) {
    const JSONObject = JSON.stringify({ nco_no: no });
    ncoHttps
      .post('/getchangeControlhistory.php', JSONObject)
      .then(({ data }) => {
        if (data.length === 0) {
          dispatch(clearHistoryReports());
          return;
        }
        console.log("data: ",data);
        if (Array.isArray(data)) {
          const details = data[0];
          const dataSource = formatHistoryTableData(details, 'nco');
          dispatch({
            type: HISTORY_DETAILS,
            data: details,
            historyTable: dataSource,
          });
        } else {
          dispatch(clearHistoryReports());
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  }
  if (module === MODULE.dnc) {
    const JSONObject = JSON.stringify({ d_no: no });
    dncHttps
      .post('/getchangeControlhistory.php', JSONObject)
      .then(({ data }) => {
        if (data.length === 0) {
          dispatch(clearHistoryReports());
          return;
        }

        if (Array.isArray(data)) {
          const details = data[0];
          const dataSource = formatHistoryTableData(details, 'dnc');
          dispatch({
            type: HISTORY_DETAILS,
            data: details,
            historyTable: dataSource,
          });
        } else {
          dispatch(clearHistoryReports());
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  }

  if (module === MODULE.mtf) {
    const JSONObject = JSON.stringify({ mf_no: no });
    mtfHttps
      .post('/getchangeControlhistory.php', JSONObject)
      .then(({ data }) => {
        if (data.length === 0) {
          dispatch(clearHistoryReports());
          return;
        }

        if (Array.isArray(data)) {
          const details = data[0];
          const dataSource = formatHistoryTableData(details, 'mtf');
          dispatch({
            type: HISTORY_DETAILS,
            data: details,
            historyTable: dataSource,
          });
        } else {
          dispatch(clearHistoryReports());
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  }

  const JSONObject = JSON.stringify({ cc_no: no });

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/getchangeControlhistory.php', JSONObject)
      .then(({ data }) => {
        if (data.length === 0) {
          dispatch(clearHistoryReports());
          return;
        }

        if (Array.isArray(data)) {
          const details = data[0];
          const dataSource = formatHistoryTableData(details);
          dispatch({
            type: HISTORY_DETAILS,
            data: details,
            historyTable: dataSource,
          });
        } else {
          dispatch(clearHistoryReports());
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  }

  https
    .post('/getchangeControlhistory.php', JSONObject)
    .then(({ data }) => {
      if (data.length === 0) {
        dispatch(clearHistoryReports());
        return;
      }

      if (Array.isArray(data)) {
        const details = data[0];
        const dataSource = formatHistoryTableData(details);
        dispatch({
          type: HISTORY_DETAILS,
          data: details,
          historyTable: dataSource,
        });
      } else {
        dispatch(clearHistoryReports());
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const clearHistoryReports = () => (dispatch) => {
  dispatch({
    type: HISTORY_DETAILS,
    data: {},
    historyTable: [],
  });
};

export const filterFormReports = (formData) => (dispatch) => {
  console.log(formData);
  const obj = JSON.stringify(formData);
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .post('/filterChangeControlFormView.php', obj)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        dispatch(memoToFileForms(res));
      })
      .catch((err) => {
        message.error('No records!');
        dispatch(memoToFileForms([]));
        console.log(err);
      });
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/filterChangeControlFormView.php', obj)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        dispatch(memoToFileForms(res));
      })
      .catch((err) => {
        message.error('No records!');
        dispatch(memoToFileForms([]));
        console.log(err);
      });
    return;
  }
  if (module === MODULE.mtf) {
    mtfHttps
      .post('/filterChangeControlFormView.php', obj)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        dispatch(memoToFileForms(res));
      })
      .catch((err) => {
        message.error('No records!');
        dispatch(memoToFileForms([]));
        console.log(err);
      });
    return;
  }
  if (module === MODULE.cmm) {
    cmmHttps
      .post('/filterChangeControlFormView.php', obj)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        dispatch(changeControlForms(res));
      })
      .catch((err) => {
        message.error('No records!');
        dispatch(changeControlForms([]));
        console.log(err);
      });
    return;
  }
  https
    .post('/filterChangeControlFormView.php', obj)
    .then(({ data }) => {
      const res = Array.isArray(data) ? data : [];
      dispatch(changeControlForms(res));
    })
    .catch((err) => {
      message.error('No records!');
      dispatch(changeControlForms([]));
      console.log(err);
    });
};

export const filterLogReports = (formData) => (dispatch) => {
  console.log(formData);
  const obj = JSON.stringify(formData);
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .post('/filterGetReports.php', obj)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        dispatch(memoToFileLogs(res));
      })
      .catch(({ response }) => {
        console.log(response);
        message.error(response.data.message);
        dispatch(memoToFileLogs(response.data.data));
        console.log(response);
      });
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/filterGetReports.php', obj)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        dispatch(memoToFileLogs(res));
      })
      .catch(({ response }) => {
        console.log(response);
        message.error(response.data.message);
        dispatch(memoToFileLogs(response.data.data));
        console.log(response);
      });
    return;
  }
  if (module === MODULE.mtf) {
    mtfHttps
      .post('/filterGetReports.php', obj)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        dispatch(memoToFileLogs(res));
      })
      .catch(({ response }) => {
        console.log(response);
        message.error(response.data.message);
        dispatch(memoToFileLogs(response.data.data));
        console.log(response);
      });
    return;
  }
  if (module === MODULE.cmm) {
    cmmHttps
      .post('/filterGetReports.php', obj)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        dispatch(changeControlLogs(res));
      })
      .catch(({ response }) => {
        console.log(response);
        message.error(response.data.message);
        dispatch(changeControlLogs(response.data.data));
        console.log(response);
      });
    return;
  }
  https
    .post('/filterGetReports.php', obj)
    .then(({ data }) => {
      const res = Array.isArray(data) ? data : [];
      dispatch(changeControlLogs(res));
    })
    .catch(({ response }) => {
      console.log(response);
      message.error(response.data.message);
      dispatch(changeControlLogs(response.data.data));
      console.log(response);
    });
};

export const getAuditReport = () => async (dispatch) => {
  let data = await fetch('http://localhost:5000/audit');
  data = await data.json();
  dispatch({
    type: AUDIT_REPORT,
    data,
  });
};

export const memoToFileForms = (res) => ({
  type: FORM_DATA,
  data: res,
  total: res ? res.length : 0,
});

export const memoToFileLogs = (res) => ({
  type: LOG_DATA,
  data: res,
  excelData: formatLogExcelData(res),
  total: res ? res.length : 0,
});

export const changeControlForms = (res) => ({
  type: FORM_DATA,
  data: res,
  total: res ? res.length : 0,
});

export const changeControlLogs = (res) => ({
  type: LOG_DATA,
  data: res,
  excelData: formatNCOLogExcelData(res, 'nc/ob'),
  total: res ? res.length : 0,
});

export const changeRCALogs = (res) => ({
  type: RCA_LOG_DATA,
  RCAdata: res,
  RCAexcelData: formatNCOLogExcelData(res, 'RCA'),
  RCAtotal: res ? res.length : 0,
});
export const changeEffectiveLogs = (res) => ({
  type: EFFECTIVE_LOG_DATA,
  Effectivedata: res,
  EffectiveexcelData: formatNCOLogExcelData(res, 'Effective'),
  Effectivetotal: res ? res.length : 0,
});

export const changeCAPALogs = (res) => ({
  type: CAPA_LOG_DATA,
  CAPAData: res,
  CAPAExcelData: formatNCOLogExcelData(res, 'CAPA'),
  CAPATotal: res ? res.length : 0,
});

export const getPrintDownloadPerms = () => async (dispatch) => {
  const module = localStorage.getItem('module');
  let response;
  dispatch({ type: RESET_REPORT_PERMS });
  try {
    if (module === MODULE.rm) {
      response = await fetch.get('getRSKReportConfig');
    } else if (module === MODULE.dnc) {
      response = await fetch.get('getDACReportConfig');
    } else if (module === MODULE.mtf) {
      response = await fetch.get('getMTFReportConfig');
    } else if (module === MODULE.nco) {
      response = await fetch.get('getCAPAReportConfig');
    } else {
      response = await fetch.get('getCCReportConfig');
    }
    dispatch({ type: GET_REPORT_PERMS, data: response.data });
  } catch (error) {
    message.error('Error getting report permissions');
  }
};
 
export const getCapaHistoryReports = (no) => (dispatch) => {
  const module = localStorage.getItem('module');

  if (!no) {
    dispatch(clearCapaHistoryReports());
    return;
  }

  if (module === MODULE.rm) {
    rmHttps
      .get(`/capaPlanHistory.php?nco_no=${no}`)
      .then(({ data }) => {
        if (data.length === 0) {
          dispatch(clearCapaHistoryReports());
          return;
        }
      const details = data;
      const dataSource = formatHistoryTableData(details.deviation_details[0], 'rm');
      console.log("data: ",details);
      console.log(dataSource);
      dispatch({
        type: CAPA_HISTORY_DATA,
        data: details,
        historyTable: dataSource,
      });
    })
      .catch((err) => {
        console.log(err);
      });
    return;
  }
  if (module === MODULE.nco) {
    
    ncoHttps
    .get(`/capaPlanHistory.php?nco_no=${no}`)
      .then(({ data }) => {
        if (data.length === 0) {
          dispatch(clearCapaHistoryReports());
          return;
        }
          const details = data;
          const dataSource = formatHistoryTableData(details.deviation_details[0], 'nco');
          console.log("data: ",details);
          console.log(dataSource);
          dispatch({
            type: CAPA_HISTORY_DATA,
            data: details,
            historyTable: dataSource,
          });
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  }
  if (module === MODULE.dnc) {
    
    dncHttps
    .get(`/capaPlanHistory.php?nco_no=${no}`)
      .then(({ data }) => {
        if (data.length === 0) {
          dispatch(clearCapaHistoryReports());
          return;
        }
      const details = data;
      const dataSource = formatHistoryTableData(details.deviation_details[0], 'dnc');
      console.log("data: ",details);
      console.log(dataSource);
      dispatch({
        type: CAPA_HISTORY_DATA,
        data: details,
        historyTable: dataSource,
      });
   
  })
      .catch((err) => {
        console.log(err);
      });
    return;
  }

  if (module === MODULE.mtf) {
    mtfHttps
    .get(`/capaPlanHistory.php?nco_no=${no}`)
      .then(({ data }) => {
        if (data.length === 0) {
          dispatch(clearCapaHistoryReports());
          return;
        }
      const details = data;
      const dataSource = formatHistoryTableData(details.deviation_details[0], 'mtf');
      console.log("data: ",details);
      console.log(dataSource);
      dispatch({
        type: CAPA_HISTORY_DATA,
        data: details,
        historyTable: dataSource,
      });
   
  })   
      .catch((err) => {
        console.log(err);
      });
    return;
  }

 

  if (module === MODULE.cmm) {
    cmmHttps
    .get(`/capaPlanHistory.php?nco_no=${no}`)
      .then(({ data }) => {
        if (data.length === 0) {
          dispatch(clearCapaHistoryReports());
          return;
        }
      const details = data;
      const dataSource = formatHistoryTableData(details.deviation_details[0]);
      console.log("data: ",details);
      console.log(dataSource);
      dispatch({
        type: CAPA_HISTORY_DATA,
        data: details,
        historyTable: dataSource,
      });
   
  }) 
      .catch((err) => {
        console.log(err);
      });
    return;
  }

  https
  .get(`/capaPlanHistory.php?nco_no=${no}`)
    .then(({ data }) => {
      if (data.length === 0) {
        dispatch(clearCapaHistoryReports());
        return;
      }
    const details = data;
    const dataSource = formatHistoryTableData(details.deviation_details[0]);
    console.log("data: ",details);
    console.log(dataSource);
    dispatch({
      type: CAPA_HISTORY_DATA,
      data: details,
      historyTable: dataSource,
    });
 
}) 
    .catch((err) => {
      console.log(err);
    });
};

export const clearCapaHistoryReports = () => (dispatch) => {
  dispatch({
    type: CAPA_HISTORY_DATA,
    data: {},
    historyTable: [],
  });
};

import React, { useState, useEffect } from 'react';
import '../../../styles/LayoutComponents/vendorEvaluation.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as modalActions from '../../../store/actions/modalActions';
import { getQuestionPaperRender } from '../../../store/actions/QPCreator/QPRAction';
import { db } from './db';
import MultipleChoice from './MultipleChoice';
import TextField from './TextField';
import TextNumber from './TextNumber';
import SubInputs from './SubInputs';
import { Spin } from 'antd';
import MultipleInput from './MultipleInput';
import RenderMultiLevelData from './RenderMultiLevelData';
import TextEntry from './TextEditor';
import Attachment from './Attachment';
const RenderQuestion = (props) => {
  const [qpData, setQPData] = useState(null);
  useEffect(() => {
    setQPData(props.activeQuestion);
  }, [props.activeQuestion]);
  console.log('test', qpData);
  const getQuestion = () => {
    if (qpData) {
      const { answer_type } = qpData;
      console.log(answer_type);
      if (props.activeSection.section_type == 'Multi Level Data') {
        return <RenderMultiLevelData />;
      } else if (answer_type === 'Multiple Choice') {
        return <MultipleChoice {...props} {...qpData} />;
      } else if (answer_type === 'Multiple Select') {
        // Add the corresponding component for Multiple Select
      } else if (answer_type === 'Dependency Select') {
        // Add the corresponding component for Dependency Select
      } else if (answer_type === 'Multiple Input') {
        // Add the corresponding component for Multiple Input
        return <MultipleInput {...props} {...qpData} />;
      } else if (answer_type === 'Sub Inputs') {
        return <SubInputs {...props} {...qpData} />;
      } else if (answer_type === 'Text Field') {
        return <TextField {...props} {...qpData} />;
      } else if (answer_type === 'Text Area') {
        // Add the corresponding component for Text Area
      } else if (answer_type === 'Text Editor') {
        return <TextEntry {...props} {...qpData} />;
      } else if (answer_type === 'Number') {
        return <TextNumber {...props} {...qpData} />;
      } else if (answer_type === 'Date Field') {
        // Add the corresponding component for Date Field
      } else if (answer_type === 'Email Address') {
        // Add the corresponding component for Email Address
      } else if (answer_type === 'Contact No') {
        // Add the corresponding component for Contact No
      } else if (answer_type === 'Rating') {
        // Add the corresponding component for Rating
      } else if (answer_type === 'Upload File') {
        return <Attachment />;
      }
    }
    return (
      <div className="d-flex justify-content-center align-items-center sectionBody">
        <Spin size="large" />
      </div>
    );
  };

  return <>{getQuestion()}</>;
};

const mapStateToProps = ({ QPRReducer }) => ({
  description: QPRReducer.description,
  paper_name: QPRReducer.paper_name,
  organization: QPRReducer.organization,
  paperId: QPRReducer.paperId,
  version: QPRReducer.version,
  activeSection: QPRReducer.activeSection,
  activeQuestion: QPRReducer.activeQuestion,
});

const mapDispatchToProps = {
  getQuestionPaperRender,
  ...modalActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RenderQuestion));

import {
  fetch,
  https,
  cmmHttps,
  mtfHttps,
  dncHttps,
  rmHttps,
  ncoHttps,
} from '../../utils/config';
import { hideModal } from './modalActions';
import { getNCOAttachmentsList } from './nco/CAPAPlanInitiation';
import { iaccessHttps } from '../../utils/config';
import axios from 'axios';
import {
  getInitiations,
  getMtfInitiations,
  getNcoInitiations,
  getActivityList,
  audit,
} from './initiationActions';
import {
  getImplementationDetails,
  getImplementationList,
} from './implementationActions';
import { message } from 'antd';
import { MODULE } from '../../utils/constants';
import { functionalReviewExcel, impTasksExcel } from '../../utils/helper';
import {
  getActivityCAPAList,
  getActivityEffectivenessList,
  getCAPAMonitorList,
} from './nco/CAPAImplementationActions';
import { mode } from 'crypto-js';
import { nco_stage_alias, nco_mail_stage } from '../../utils/constants';
export const GET_ASSIGNEE_LIST = 'GET_ASSIGNEE_LIST';
export const GET_NEXT_ASSIGNEES = 'GET_NEXT_ASSIGNEES';
export const GET_ATTACHMENTS_LIST = 'GET_ATTACHMENTS_LIST';
export const UPLOAD_ATTACHMENTS = 'UPLOAD_ATTACHMENTS';
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';
export const DELETE_UPLOAD_ATTACHMENT = 'DELETE_UPLOAD_ATTACHMENT';
export const CLEAR_ATTACHMENTS = 'CLEAR_ATTACHMENTS';
export const GET_PURPOSE_LIST = 'GET_PURPOSE_LIST';
export const FILE_LOADER = 'FILE_LOADER';
export const UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT';
export const GET_FUNCTIONAL_REVIEW_LIST = 'GET_FUNCTIONAL_REVIEW_LIST';
export const RESPONSIBLE_PERSONS = 'RESPONSIBLE_PERSONS';
export const FUNCTIONAL_REVIEW_DETAILS = 'FUNCTIONAL_REVIEW_DETAILS';
export const GET_IMP_TASK_LIST = 'GET_IMP_TASK_LIST';
export const IMP_TASK_DETAILS = 'IMP_TASK_DETAILS';
export const SET_COMMON_ATTRIBUTES = 'SET_COMMON_ATTRIBUTES';
export const CREATE_REQUEST_TYPE = 'CREATE_REQUEST_TYPE';
export const allRequest = 'allRequest';
export const CAPA_CONFIG = 'CAPA_CONFIG';
export const IMP_EFFECTIVE_DETAILS = 'IMP_EFFECTIVE_DETAILS';
export const IMP_TASK_DETAILS2 = 'IMP_TASK_DETAILS2';
export const SET_NCO_FILTERS = 'SET_NCO_FILTERS';

export const setFilters = (filters) => ({
  type: SET_NCO_FILTERS,
  payload: filters,
});
// export const getAssigneeList = () => (dispatch) => {
// 	const role = localStorage.getItem('role');
// 	const username = localStorage.getItem('username');
// 	const obj = JSON.stringify({ role, username });
// 	https
// 		.post('/getChangeassignee.php', obj)
// 		.then(({ data }) => {
// 			dispatch({
// 				type: GET_ASSIGNEE_LIST,
// 				data
// 			});
// 		})
// 		.catch((err) => console.log(err));
// };

export const getAssigneeList = () => (dispatch) => {
  const token = localStorage.getItem('access_token');
  fetch
    .get('/getAssignee/', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data }) => {
      dispatch({
        type: GET_ASSIGNEE_LIST,
        data: data.data,
      });
    })
    .catch(({ response }) => {
      dispatch({
        type: GET_ASSIGNEE_LIST,
        data: [],
      });
      console.log(response);
    });
};

// export const getNextAssignee = () => (dispatch) => {
// 	const role = localStorage.getItem('role');
// 	const obj = JSON.stringify({ role });
// 	https
// 		.post('/getAssignee.php', obj)
// 		.then(({ data }) => {
// 			dispatch({
// 				type: GET_NEXT_ASSIGNEES,
// 				data
// 			});
// 		})
// 		.catch((err) => console.log(err));
// };

export const getNextAssignee = () => (dispatch) => {
  const token = localStorage.getItem('access_token');

  fetch
    .get('/getNextAssignee/', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data }) => {
      dispatch({
        type: GET_NEXT_ASSIGNEES,
        data: data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_NEXT_ASSIGNEES,
        data: [],
      });
      console.log(err);
    });
};

export const changeInitiationAssignee = (formData) => (dispatch) => {
  const obj = JSON.stringify(formData);
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');

  if (module === MODULE.mtf) {
    mtfHttps
      .post('/changeAssignee.php', obj)
      .then((_) => {
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'ASSIGN_TO',
            modalProps: { show: false },
          })
        );
        dispatch(getMtfInitiations());
      })
      .catch((err) => {
        message.error('Request not Submited');
        console.log(err);
      });
    return;
  }
  if (module === MODULE.cmm) {
    cmmHttps
      .post('/changeAssignee.php', obj)
      .then((_) => {
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'ASSIGN_TO',
            modalProps: { show: false },
          })
        );
        dispatch(getInitiations());
      })
      .catch((err) => console.log(err));
    return;
  }
  https
    .post('/changeAssignee.php', obj)
    .then((_) => {
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'ASSIGN_TO',
          modalProps: { show: false },
        })
      );
      dispatch(getInitiations());
    })
    .catch((err) => console.log(err));
};

export const changeAssignee = (formData) => (dispatch) => {
  const obj = JSON.stringify(formData);
  const role = localStorage.getItem('role');
  const mailObj = {
    email_type: 'assignment',
    stage: nco_stage_alias[role],
    stage_index: nco_mail_stage[role],
    action_by: formData.username,
    assigned_to: formData.assignee,
    assigned_comment: formData.activity_comments,
    nco_no: formData.nco_no,
  };

  ncoHttps
    .post('/changeAssignee.php', obj)
    .then((_) => {
      sendEmail(mailObj);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'ASSIGN_TO',
          modalProps: { show: false },
        })
      );
      dispatch(getNcoInitiations(formData.req));
    })
    .catch((err) => console.log(err));
};

export const changeEffectivenessAssignee = (formData) => (dispatch) => {
  // const obj = JSON.stringify(formData);
  const role = localStorage.getItem('role');
  console.log(formData.req);
  const checkObj = {
    current_handler: formData.current_handler,
    assigned_by: formData.assigned_by,
    version: formData.version,
    capa_no: formData.capa_no,
    check_no: formData.check_no,
    periodic_date: formData.periodic_date,
    nco_no: formData.nco_no,
  };

  const mailObj = {
    email_type: 'assignment',
    stage: nco_stage_alias[role],
    stage_index: nco_mail_stage[role],
    action_by: formData.username,
    assigned_to: formData.assignee,
    assigned_comment: formData.activity_comments,
    nco_no: formData.nco_no,
  };

  console.log(mailObj);

  ncoHttps
    .post('/changeAssigneeEffectivenessCheck.php', checkObj)
    .then((_) => {
      sendEmail(mailObj);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'ASSIGN_TO',
          modalProps: { show: false },
        })
      );
      dispatch(getCAPAMonitorList(formData.req));
    })
    .catch((err) => console.log(err));
};

export const changeImplementationAssignee = (formData) => (dispatch) => {
  const module = localStorage.getItem('module');
  const obj = JSON.stringify(formData);
  console.log('imple', obj);
  if (module === MODULE.cmm) {
    cmmHttps
      .post('/changeAssigneeimplementation.php', obj)
      .then((_) => {
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'ASSIGN_TO',
            modalProps: { show: false },
          })
        );
        dispatch(getImplementationList());
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.nco) {
    ncoHttps
      .post('/changeAssigneeimplementation.php', obj)
      .then((_) => {
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'ASSIGN_TO',
            modalProps: { show: false },
          })
        );
        dispatch(getImplementationList());
      })
      .catch((err) => console.log(err));
    return;
  }
  https
    .post('/changeAssigneeimplementation.php', obj)
    .then((_) => {
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'ASSIGN_TO',
          modalProps: { show: false },
        })
      );
      dispatch(getImplementationList());
    })
    .catch((err) => console.log(err));
};

export const uploadAttachment = (file, nco_no) => (dispatch) => {
  // const module = localStorage.getItem('module');
  const type = 'attachment';
  const module = localStorage.getItem('module');
  dispatch({ type: FILE_LOADER, loading: true });
  const formData = new FormData();
  formData.append(`attachments[0]`, file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  if (module === MODULE.rm) {
    rmHttps
      .post('/AWSS3/index.php', formData, config)
      .then(({ data }) => {
        console.log(data);
        let res;
        // if (role !== 'general') {
        res = { ...data[0], doc_type: 'Others', activity_comments: '' };
        // } else {
        //   res = data[0];
        // }
        console.log('files', res);
        dispatch({
          type: UPLOAD_ATTACHMENTS,
          data: res,
          loading: false,
        });

        message.success('Attachments Upload Successful');
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FILE_LOADER, loading: false });
        message.error('Attachments Upload Failed! Try again.');
      });
    return;
  }
  if (module === MODULE.dnc) {
    dncHttps
      .post('/AWSS3/index.php', formData, config)
      .then(({ data }) => {
        console.log(data);
        let res;
        // if (role !== 'general') {
        res = { ...data[0], doc_type: 'Others', activity_comments: '' };
        // } else {
        //   res = data[0];
        // }
        console.log('files', res);
        dispatch({
          type: UPLOAD_ATTACHMENTS,
          data: res,
          loading: false,
        });
        message.success('Attachments Upload Successful');
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FILE_LOADER, loading: false });
        message.error('Attachments Upload Failed! Try again.');
      });
    return;
  }
  if (module === MODULE.mtf) {
    mtfHttps
      .post('/AWSS3/index.php', formData, config)
      .then(({ data }) => {
        console.log(data);
        let res;
        // if (role !== 'general') {
        res = { ...data[0], doc_type: 'Others', activity_comments: '' };
        // } else {
        //   res = data[0];
        // }
        console.log('files', res);
        dispatch({
          type: UPLOAD_ATTACHMENTS,
          data: res,
          loading: false,
        });
        message.success('Attachments Upload Successful');
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FILE_LOADER, loading: false });
        message.error('Attachments Upload Failed! Try again.');
      });
    return;
  }
  if (module === MODULE.cmm) {
    cmmHttps
      .post('/AWSS3/index.php', formData, config)
      .then(({ data }) => {
        console.log(data);
        let res;
        res = { ...data[0], doc_type: 'Others', activity_comments: '' };
        console.log('files', res);
        dispatch({
          type: UPLOAD_ATTACHMENTS,
          data: res,
          loading: false,
        });
        message.success('Attachments Upload Successful');
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FILE_LOADER, loading: false });
        message.error('Attachments Upload Failed! Try again.');
      });
    return;
  }
  if (module === MODULE.nco) {
    ncoHttps
      .post('/AWSS3/index.php', formData, config)
      // const nco_no=sessionStorage.getItem(nco_no)
      // console.log(nco_no)
      .then(({ data }) => {
        console.log(nco_no);
        console.log(data);
        let res;
        res = {
          ...data[0],
          doc_type: 'Others',
          activity_comments: '',
        };

        dispatch({
          type: UPLOAD_ATTACHMENTS,
          data: res,
          loading: false,
        });
        // dispatch(audit({ "attachments":data[0], type: "attachments",nco_no }));
        message.success('Attachments Upload Successful');
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FILE_LOADER, loading: false });
        message.error('Attachments Upload Failed! Try again.');
      });
    return;
  }
  https
    .post('/AWSS3/index.php', formData, config)
    .then(({ data }) => {
      console.log(data);
      let res;
      res = { ...data[0], doc_type: 'Others', activity_comments: '' };
      console.log('files', res);
      dispatch({
        type: UPLOAD_ATTACHMENTS,
        data: res,
        loading: false,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: FILE_LOADER, loading: false });
      message.error('Attachments Upload Failed! Try again.');
    });
};

export const deleteUploadAttachment =
  (filename, id, type, task_id) => (dispatch) => {
    // const module = localStorage.getItem('module');
    const module = localStorage.getItem('module');
    const role = localStorage.getItem('role');
    message.loading({ content: 'Removing Attachment...', key: 'updatable' });
    const formData = { s3_file_name: [filename], role, type };
    const obj = JSON.stringify(formData);
    console.log('REMOVE_ATTACHMENT', obj);
    if (module === MODULE.rm) {
      rmHttps
        .post('/AWSS3/deleteBack.php', JSON.stringify({ ...formData, id }))
        .then((_) => {
          dispatch({
            type: DELETE_UPLOAD_ATTACHMENT,
            s3_file_name: filename,
            id,
            notUploded: true,
          });
          message.success({ content: 'Attachment Removed', key: 'updatable' });
        })
        .catch((err) => {
          console.log(err);
          message.error('Failed! Try again.');
        });
      return;
    }
    if (module === MODULE.dnc) {
      dncHttps
        .post('/AWSS3/deleteBack.php', JSON.stringify({ ...formData, id }))
        .then((_) => {
          dispatch({
            type: DELETE_UPLOAD_ATTACHMENT,
            s3_file_name: filename,
            id,
            notUploded: true,
          });
          message.success({ content: 'Attachment Removed', key: 'updatable' });
        })
        .catch((err) => {
          console.log(err);
          message.error('Failed! Try again.');
        });
      return;
    }
    if (module === MODULE.mtf) {
      mtfHttps
        .post('/AWSS3/deleteBack.php', JSON.stringify({ ...formData, id }))
        .then((_) => {
          dispatch({
            type: DELETE_UPLOAD_ATTACHMENT,
            s3_file_name: filename,
            id,
            notUploded: true,
          });
          message.success({ content: 'Attachment Removed', key: 'updatable' });
        })
        .catch((err) => {
          console.log(err);
          message.error('Failed! Try again.');
        });
      return;
    }
    if (module === MODULE.cmm) {
      cmmHttps
        .post('/AWSS3/deleteBack.php', JSON.stringify({ ...formData, id }))
        .then((_) => {
          dispatch({
            type: DELETE_UPLOAD_ATTACHMENT,
            s3_file_name: filename,
            id,
          });
          if (type === 'task') {
            dispatch(getTaskDetails({ id: task_id }));
          }
          message.success({ content: 'Attachment Removed', key: 'updatable' });
        })
        .catch((err) => {
          console.log(err);
          message.error('Failed! Try again.');
        });
      return;
    }
    if (module === MODULE.nco) {
      ncoHttps
        .post('/AWSS3/deleteBack.php', JSON.stringify({ ...formData, id }))
        .then((_) => {
          dispatch({
            type: DELETE_UPLOAD_ATTACHMENT,
            s3_file_name: filename,
            id,
          });
          if (type === 'task') {
            dispatch(getTaskDetails({ id: task_id }));
          }
          message.success({ content: 'Attachment Removed', key: 'updatable' });
        })
        .catch((err) => {
          console.log(err);
          message.error('Failed! Try again.');
        });
      return;
    }
    https
      .post('/AWSS3/deleteBack.php', JSON.stringify({ ...formData, id }))
      .then((_) => {
        dispatch({
          type: DELETE_UPLOAD_ATTACHMENT,
          s3_file_name: filename,
          id,
        });
        if (type === 'task') {
          dispatch(getTaskDetails({ id: task_id }));
        }
        message.success({ content: 'Attachment Removed', key: 'updatable' });
      })
      .catch((err) => {
        console.log(err);
        message.error('Failed! Try again.');
      });
  };

export const bulkAttachmentDelete = (file_names) => (dispatch) => {
  const role = localStorage.getItem('role');
  const module = localStorage.getItem('module');
  const formData = { s3_file_name: file_names, role };
  const obj = JSON.stringify(formData);
  console.log('REMOVE_ATTACHMENT', obj);

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/AWSS3/deleteBack.php', obj)
      .then((_) => {
        console.log('Cleared Loaded Attachments');
      })
      .catch((err) => {
        console.log(err);
        message.error('Failed! Try again.');
      });
    return;
  }

  https
    .post('/AWSS3/deleteBack.php', obj)
    .then((_) => {
      console.log('Cleared Loaded Attachments');
    })
    .catch((err) => {
      console.log(err);
      message.error('Failed! Try again.');
    });
};

export const getAttachmentsList =
  (cc_no, formData, attachment_role, flow) => (dispatch) => {
    console.log(cc_no);
    console.log(flow);

    // const module = localStorage.getItem('module');
    const module = localStorage.getItem('module');
    if (module === MODULE.rm) {
      rmHttps
        .post('/AWSS3/getAttachments.php', cc_no)
        .then(({ data }) => {
          dispatch({
            type: GET_ATTACHMENTS_LIST,
            data,
          });
        })
        .catch((err) => console.log(err));
      return;
    }

    if (module === MODULE.dnc) {
      dncHttps
        .post('/AWSS3/getAttachments.php', cc_no)
        .then(({ data }) => {
          dispatch({
            type: GET_ATTACHMENTS_LIST,
            data,
          });
        })
        .catch((err) => console.log(err));
      return;
    }

    if (module === MODULE.mtf) {
      mtfHttps
        .post('/AWSS3/getAttachments.php', cc_no)
        .then(({ data }) => {
          dispatch({
            type: GET_ATTACHMENTS_LIST,
            data,
          });
        })
        .catch((err) => console.log(err));
      return;
    }
    if (module === MODULE.cmm) {
      cmmHttps
        .post('/AWSS3/getAttachments.php', cc_no)
        .then(({ data }) => {
          dispatch({
            type: GET_ATTACHMENTS_LIST,
            data,
          });
        })
        .catch((err) => console.log(err));
      return;
    }
    if (module === MODULE.nco) {
      ncoHttps
        .post(`/AWSS3/getAttachments.php?nco_no=${cc_no}`)
        .then(({ data }) => {
          dispatch({
            type: GET_ATTACHMENTS_LIST,
            data,
            formData,
            attachment_role,
            flow,
          });
        })

        .catch((err) => console.log(err));
      return;
    }
    https
      .post('/AWSS3/getAttachments.php', cc_no)
      .then(({ data }) => {
        console.log(data);
        dispatch({
          type: GET_ATTACHMENTS_LIST,
          data,
        });
      })
      .catch((err) => console.log(err));
  };

export const deleteAttachment = (formData) => (dispatch) => {
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');
  message.loading({ content: 'Deleting...', key: 'updatable' });
  const obj = JSON.stringify(formData);
  console.log('DELETE_ATTACHMENT', obj);

  if (module === MODULE.mtf) {
    mtfHttps
      .post('/AWSS3/delete.php', obj)
      .then((_) => {
        dispatch({
          type: DELETE_ATTACHMENT,
          s3_file_name: formData.s3_file_name,
        });
        message.success({ content: 'Deleted', key: 'updatable' });
      })
      .catch((err) => {
        console.log(err);
        message.error('Delete Failed! Try again.');
      });
    return;
  }
  if (module === MODULE.cmm) {
    cmmHttps
      .post('/AWSS3/delete.php', obj)
      .then((_) => {
        dispatch({
          type: DELETE_ATTACHMENT,
          s3_file_name: formData.s3_file_name,
        });
        message.success({ content: 'Deleted', key: 'updatable' });
      })
      .catch((err) => {
        console.log(err);
        message.error('Delete Failed! Try again.');
      });
    return;
  }
  if (module === MODULE.nco) {
    ncoHttps
      .post('/AWSS3/delete.php', obj)
      .then((_) => {
        dispatch({
          type: DELETE_ATTACHMENT,
          s3_file_name: formData.s3_file_name,
        });
        message.success({ content: 'Deleted', key: 'updatable' });
      })
      .catch((err) => {
        console.log(err);
        message.error('Delete Failed! Try again.');
      });
    return;
  }
  https
    .post('/AWSS3/delete.php', obj)
    .then((_) => {
      dispatch({
        type: DELETE_ATTACHMENT,
        s3_file_name: formData.s3_file_name,
      });
      message.success({ content: 'Deleted', key: 'updatable' });
    })
    .catch((err) => {
      console.log(err);
      message.error('Delete Failed! Try again.');
    });
};
export const deleteRCAAttachment = (formData) => (dispatch) => {
  ncoHttps
    .post(`/rca/attachmentDelete.php?id=${formData.id}`)
    .then((_) => {
      dispatch({
        type: DELETE_ATTACHMENT,
        s3_file_name: formData.s3_file_name,
      });
      message.success({ content: 'Deleted', key: 'updatable' });
    })
    .catch((err) => {
      console.log(err);
      message.error('Delete Failed! Try again.');
    });
  return;
};
export const updateAttachment =
  (index, type, value) => (dispatch, getState) => {
    const attachments = getState().common.uploadList;
    const data = attachments.map((a, i) =>
      i === index ? { ...a, [type]: value } : a
    );
    dispatch({
      type: UPDATE_ATTACHMENT,
      data,
    });
  };

export const getPurposeList = () => (dispatch) => {
  // const role = localStorage.getItem('role');
  // const username = localStorage.getItem('username');
  // const obj = JSON.stringify({ role, username });
  // https
  // 	.post('/getChangeassignee.php', obj)
  // 	.then(({ data }) => {
  dispatch({
    type: GET_PURPOSE_LIST,
    data: ['Audit', 'Internal Review'],
  });
  // })
  // .catch((err) => console.log(err));
};

export const getResponsiblePerson = () => (dispatch, getState) => {
  const res_persons = getState().common.responsible_persons;
  const organization = localStorage.getItem('organization');
  if (!(res_persons.length > 0)) {
    fetch
      .post('/GetGeneralProfile/', { organization })
      .then(({ data }) => {
        dispatch({
          type: RESPONSIBLE_PERSONS,
          data: data,
        });
      })
      .catch((err) => console.err(err));
  }
};

export const getFunctionalReviews = () => (dispatch) => {
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');
  const username = localStorage.getItem('username');

  if (module === MODULE.rm) {
    const obj = JSON.stringify({ action_owner: username });
    rmHttps
      .post('/getFunctionalReviewView.php', obj)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        const excel_data = functionalReviewExcel(data);
        dispatch({
          type: GET_FUNCTIONAL_REVIEW_LIST,
          data: res,
          total: res.length,
          excel_data,
        });
      })
      .catch(({ response }) => {
        // message.error(response.data[0].message);
        console.error(response);
        dispatch({
          type: GET_FUNCTIONAL_REVIEW_LIST,
          data: [],
          total: 0,
        });
      });
    return;
  }

  if (module === MODULE.dnc) {
    const obj = JSON.stringify({ action_owner: username });
    dncHttps
      .post('/getFunctionalReviewView.php', obj)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        const excel_data = functionalReviewExcel(data);
        dispatch({
          type: GET_FUNCTIONAL_REVIEW_LIST,
          data: res,
          total: res.length,
          excel_data,
        });
      })
      .catch(({ response }) => {
        // message.error(response.data[0].message);
        console.error(response);
        dispatch({
          type: GET_FUNCTIONAL_REVIEW_LIST,
          data: [],
          total: 0,
        });
      });
    return;
  }

  const obj = JSON.stringify({ responsible_person: username });

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/getFunctionalReviewView.php', obj)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        const excel_data = functionalReviewExcel(data);
        dispatch({
          type: GET_FUNCTIONAL_REVIEW_LIST,
          data: res,
          total: res.length,
          excel_data,
        });
      })
      .catch(({ response }) => {
        // message.error(response.data[0].message);
        console.error(response);
        dispatch({
          type: GET_FUNCTIONAL_REVIEW_LIST,
          data: [],
          total: 0,
        });
      });
    return;
  }

  https
    .post('/getFunctionalReviewView.php', obj)
    .then(({ data }) => {
      const res = Array.isArray(data) ? data : [];
      const excel_data = functionalReviewExcel(data);
      dispatch({
        type: GET_FUNCTIONAL_REVIEW_LIST,
        data: res,
        total: res.length,
        excel_data,
      });
    })
    .catch(({ response }) => {
      // message.error(response.data[0].message);
      console.error(response);
      dispatch({
        type: GET_FUNCTIONAL_REVIEW_LIST,
        data: [],
        total: 0,
      });
    });
};

export const getReviewDetails = (formData) => (dispatch) => {
  const obj = JSON.stringify(formData);
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .post('/getFunctionalReview.php', obj)
      .then(({ data }) => {
        dispatch({
          type: FUNCTIONAL_REVIEW_DETAILS,
          data,
        });
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/getFunctionalReview.php', obj)
      .then(({ data }) => {
        dispatch({
          type: FUNCTIONAL_REVIEW_DETAILS,
          data,
        });
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.cmmHttps) {
    cmmHttps
      .post('/getFunctionalReview.php', obj)
      .then(({ data }) => {
        dispatch({
          type: FUNCTIONAL_REVIEW_DETAILS,
          data,
        });
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .post('/getFunctionalReview.php', obj)
    .then(({ data }) => {
      dispatch({
        type: FUNCTIONAL_REVIEW_DETAILS,
        data,
      });
    })
    .catch((err) => console.log(err));
};

export const getActionItemsDetails = (formData) => (dispatch) => {
  const username = localStorage.getItem('username');
  const name1 = localStorage.getItem('name');
  formData.action_owner = `${name1} (${username})`;

  const obj = JSON.stringify(formData);

  ncoHttps
    .post('/getFunctionalReviewAction.php', obj)
    .then(({ data }) => {
      console.log(data);
      dispatch({
        type: FUNCTIONAL_REVIEW_DETAILS,
        data: data[0],
      });
      dispatch(getNCOAttachmentsList(formData.nco_no));
    })
    .catch((err) => console.log(err));
};

export const submitReview = (formData, history) => (dispatch) => {
  const obj = JSON.stringify(formData);
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    const mailObj = {
      email_type: 'd_action_complete',
      action_by: localStorage.getItem('username'),
      risk_id: formData.risk_id,
      type: 'fr',
      id: formData.id,
    };

    rmHttps
      .post('/newReviewFunctional.php', obj)
      .then((_) => {
        sendMailAction(mailObj);
        message.success('Risk Action Items Completed.');
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        history.push('/rm/functional-reviews');
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    const mailObj = {
      email_type: 'd_action_complete',
      action_by: localStorage.getItem('username'),
      d_no: formData.d_no,
      type: 'fr',
      id: formData.id,
    };

    dncHttps
      .post('/newReviewFunctional.php', obj)
      .then((_) => {
        sendMailAction(mailObj);
        message.success('Deviation Action Items Completed.');
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        history.push('/dnc/functional-reviews');
      })
      .catch((err) => console.log(err));
    return;
  }

  const mailObj = {
    email_type: 'func_review_complete',
    action_by: localStorage.getItem('username'),
    cc_no: formData.cc_no,
    type: 'fr',
    id: formData.id,
  };

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/newReviewFunctional.php', obj)
      .then((_) => {
        sendMailAction(mailObj);
        message.success('Functional Review Completed.');
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        history.push('/iControl/functional-reviews');
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .post('/newReviewFunctional.php', obj)
    .then((_) => {
      sendMailAction(mailObj);
      message.success('Functional Review Completed.');
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      history.push('/iControl/functional-reviews');
    })
    .catch((err) => console.log(err));
};

export const saveReview = (formBody) => (dispatch) => {
  const obj = JSON.stringify(formBody);
  // const module = localStorage.getItem('module');\
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .post('/saveReviewFunctional.php', obj)
      .then((_) => {
        message.success('Risk Action Items Saved.');
        dispatch(getReviewDetails({ id: formBody.id }));
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/saveReviewFunctional.php', obj)
      .then((_) => {
        message.success('Deviation Action Items Saved.');
        dispatch(getReviewDetails({ id: formBody.id }));
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/saveReviewFunctional.php', obj)
      .then((_) => {
        message.success('Functional Review Saved.');
        dispatch(getReviewDetails({ id: formBody.id }));
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .post('/saveReviewFunctional.php', obj)
    .then((_) => {
      message.success('Functional Review Saved.');
      dispatch(getReviewDetails({ id: formBody.id }));
    })
    .catch((err) => console.log(err));
};

export const getImpTasks = () => (dispatch) => {
  const username = localStorage.getItem('username');
  const name = localStorage.getItem('name');
  const nco_organisation = localStorage.getItem('organization');
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    const obj = JSON.stringify({ action_owner: username });
    rmHttps
      .post('/getTasksView.php', obj)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        const excel_data = impTasksExcel(res);
        dispatch({
          type: GET_IMP_TASK_LIST,
          data: res,
          total: res.length,
          excel_data,
        });
      })
      .catch(({ response }) => {
        message.error(response.data[0].message);
        dispatch({
          type: GET_IMP_TASK_LIST,
          data: [],
          total: 0,
        });
      });
    return;
  }

  if (module === MODULE.dnc) {
    const obj = JSON.stringify({ action_owner: username });
    dncHttps
      .post('/getTasksView.php', obj)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        const excel_data = impTasksExcel(res);
        dispatch({
          type: GET_IMP_TASK_LIST,
          data: res,
          total: res.length,
          excel_data,
        });
      })
      .catch(({ response }) => {
        message.error(response.data[0].message);
        dispatch({
          type: GET_IMP_TASK_LIST,
          data: [],
          total: 0,
        });
      });
    return;
  }
  const obj = JSON.stringify({ responsible_person: username });
  if (module === MODULE.cmmHttps) {
    cmmHttps
      .post('/getTasksView.php', obj)
      .then(({ data }) => {
        const res = Array.isArray(data) ? data : [];
        const excel_data = impTasksExcel(res);
        dispatch({
          type: GET_IMP_TASK_LIST,
          data: res,
          total: res.length,
          excel_data,
        });
      })
      .catch(({ response }) => {
        message.error(response.data[0].message);
        dispatch({
          type: GET_IMP_TASK_LIST,
          data: [],
          total: 0,
        });
      });
    return;
  }
  https
    .post('/getTasksView.php', obj)
    .then(({ data }) => {
      const res = Array.isArray(data) ? data : [];
      const excel_data = impTasksExcel(res);
      dispatch({
        type: GET_IMP_TASK_LIST,
        data: res,
        total: res.length,
        excel_data,
      });
    })
    .catch(({ response }) => {
      message.error(response.data[0].message);
      dispatch({
        type: GET_IMP_TASK_LIST,
        data: [],
        total: 0,
      });
    });
};

export const getTaskDetails = (formData) => (dispatch) => {
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');
  const action_owner = `${localStorage.getItem(
    'username'
  )} (${localStorage.getItem('username')})`;
  const obj = JSON.stringify(formData);

  if (module === MODULE.rm) {
    rmHttps
      .post('/getTask.php', obj)
      .then(({ data }) => {
        dispatch({
          type: IMP_TASK_DETAILS,
          data,
        });
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/getTask.php', obj)
      .then(({ data }) => {
        dispatch({
          type: IMP_TASK_DETAILS,
          data,
        });
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.nco) {
    const dataOb = {
      nco_no: formData.id,
      capa_no: formData.capa_no,
      chk_no: formData.chk_no,
      check_id: formData.check_id,
    };

    ncoHttps
      .post(`/getTask.php`, dataOb)
      .then(({ data }) => {
        if (formData.modal) {
          dispatch({
            type: IMP_TASK_DETAILS2,
            data,
          });
        } else {
          dispatch(getNCOAttachmentsList(formData.id));
          if (formData.chk_no) {
            dispatch(
              getActivityEffectivenessList({
                ...formData,
                version: data['effectiveness_data'][0].version,
                periodic_date: data['effectiveness_data'][0].periodic_date,
              })
            );
          } else {
            dispatch(getActivityCAPAList(dataOb));
          }
          dispatch({
            type: IMP_TASK_DETAILS,
            data,
          });
        }
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/getTask.php', obj)
      .then(({ data }) => {
        dispatch({
          type: IMP_TASK_DETAILS,
          data,
        });
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .post('/getTask.php', obj)
    .then(({ data }) => {
      dispatch({
        type: IMP_TASK_DETAILS,
        data,
      });
    })
    .catch((err) => console.log(err));
};

export const getVendorTaskDetails = (formData) => (dispatch) => {
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');
  const action_owner = `${localStorage.getItem(
    'username'
  )} (${localStorage.getItem('username')})`;
  const obj = JSON.stringify(formData);

  ncoHttps
    .post(`/getTask.php`, obj)
    .then(({ data }) => {
      if (formData.modal) {
        dispatch({
          type: IMP_TASK_DETAILS2,
          data,
        });
      } else {
        dispatch(getActivityList(obj));
        dispatch(getNCOAttachmentsList(formData.id));
        dispatch({
          type: IMP_TASK_DETAILS,
          data,
        });
      }
    })
    .catch((err) => console.log(err));
  return;
};

export const submitTaskComments = (formData, history, type) => (dispatch) => {
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');
  const obj = JSON.stringify(formData);

  if (module === MODULE.rm) {
    const mailObj = {
      email_type: 'capa_complete',
      action_by: localStorage.getItem('username'),
      risk_id: formData.risk_id,
      id: formData.id,
    };

    rmHttps
      .post('/newTask.php', obj)
      .then(() => {
        sendEmail(mailObj);
        message.success(`Task ${type === 'submit' ? 'Completed' : 'Updated'}.`);
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        if (type === 'submit') history.push('/rm/implementation-tasks');
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    const mailObj = {
      email_type: 'capa_complete',
      action_by: localStorage.getItem('username'),
      d_no: formData.d_no,
      id: formData.id,
    };

    dncHttps
      .post('/newTask.php', obj)
      .then(() => {
        sendMailAction(mailObj);
        message.success(`Task ${type === 'submit' ? 'Completed' : 'Updated'}.`);
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        if (type === 'submit') history.push('/dnc/implementation-tasks');
      })
      .catch((err) => console.log(err));
    return;
  }

  const mailObj = {
    email_type: 'implement_complete',
    action_by: localStorage.getItem('username'),
    cc_no: formData.cc_no,
    id: formData.id,
  };

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/newTask.php', obj)
      .then(() => {
        sendEmail(mailObj);
        message.success(`Task ${type === 'submit' ? 'Completed' : 'Updated'}.`);
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        if (type === 'submit') history.push('/iControl/implementation-tasks');
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .post('/newTask.php', obj)
    .then(() => {
      sendEmail(mailObj);
      message.success(`Task ${type === 'submit' ? 'Completed' : 'Updated'}.`);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      if (type === 'submit') history.push('/iControl/implementation-tasks');
    })
    .catch((err) => console.log(err));
};

export const sendMailAction = (mailObj) => {
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');
  if (module === MODULE.rm) {
    rmHttps
      .post('/SendEmailAction.php', mailObj)
      .then(() => console.log('Mail triggered: Successful'))
      .catch(() => console.log('Mail triggered: Failed'));

    return;
  }
  if (module === MODULE.dnc) {
    dncHttps
      .post('/SendEmailAction.php', mailObj)
      .then(() => console.log('Mail triggered: Successful'))
      .catch(() => console.log('Mail triggered: Failed'));

    return;
  }
  if (module === MODULE.cmm) {
    cmmHttps
      .post('/SendEmailAction.php', mailObj)
      .then(() => console.log('Mail triggered: Successful'))
      .catch(() => console.log('Mail triggered: Failed'));
    return;
  }
  https
    .post('/SendEmailAction.php', mailObj)
    .then(() => console.log('Mail triggered: Successful'))
    .catch(() => console.log('Mail triggered: Failed'));
};

export const sendEmail = (mailObj) => {
  console.log(mailObj);
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');
  if (module === MODULE.rm) {
    rmHttps
      .post('/SendEmail.php', mailObj)
      .then(() => console.log('Mail triggered: Successful'))
      .catch(() => console.log('Mail triggered: Failed'));

    return;
  }
  if (module === MODULE.dnc) {
    dncHttps
      .post('/SendEmail.php', mailObj)
      .then(() => console.log('Mail triggered: Successful'))
      .catch(() => console.log('Mail triggered: Failed'));

    return;
  }
  if (module === MODULE.nco) {
    ncoHttps
      .post('/SendEmail.php', mailObj)
      .then(() => console.log('Mail triggered: Successful'))
      .catch(() => console.log('Mail triggered: Failed'));

    return;
  }
  if (module === MODULE.mtf) {
    const token = localStorage.getItem('access_token');
    mtfHttps
      .post('/SendEmail.php', {
        mailObj,
        token,
      })
      .then(() => console.log('Mail triggered: Successful'))
      .catch(() => console.log('Mail triggered: Failed'));
    return;
  }
  if (module === MODULE.cmm) {
    cmmHttps
      .post('/SendEmail.php', mailObj)
      .then(() => console.log('Mail triggered: Successful'))
      .catch(() => console.log('Mail triggered: Failed'));
    return;
  }
  https
    .post('/SendEmail.php', mailObj)
    .then(() => console.log('Mail triggered: Successful'))
    .catch(() => console.log('Mail triggered: Failed'));
};

export const setRequestType = (request_type) => (dispatch) => {
  return dispatch({
    type: CREATE_REQUEST_TYPE,
    data: request_type,
  });
};
export const setAllRequest = (newValue) => ({
  type: 'allRequest',
  payload: newValue,
});

export const CAPAConfig = () => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    const data = {
      type: 'global_config',
    };
    axios
      .post(`${process.env.REACT_APP_IACCESS_URL}/globalConfig/`, data, config)
      .then(({ data }) => {
        dispatch({ type: CAPA_CONFIG, data });
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const addHolidays = (formBody) => (dispatch) => {
  iaccessHttps
    .post(`/addHolidays/`, formBody)
    .then(({ data }) => {
      message.success('Holiday Submitted Successfully');
    })
    .catch((err) => {
      console.log(err);
    });
  return;
};
